<script>
  import { _ } from "svelte-i18n";
  import { startup_state, call_collapse_moods } from "$lib/store.js";
  import { onMount } from "svelte";
  import Keyword from "$lib/Keyword.svelte";

  $: keywordsArray = Array.from({ length: 22 }, (value, i) => ({
    category: $_("Mood"),
    displayText: $_(`mood${i + 1}`),
    id: `mood${i + 1}`,
    incl_excl: "",
    type: "AD_HOC"
  }));

  let searchComponent;
  let keywordsDiv;
  let searchFieldContainer;
  let spacer;

  let isMoodsExpanded = false;
  let keywordInspirations;
  let moods;
  let moodsRect;
  let searchElements;
  let claim;

  call_collapse_moods.subscribe((action) => {
    if (action && isMoodsExpanded) {
      collapseMoods();
    }
    call_collapse_moods.set(false);
  });

  startup_state.subscribe(() => {
    if (keywordInspirations) {
      let claimHeight = 0;
      if (claim) {
        claimHeight = claim.scrollHeight;
      }
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`; // in case it was set to 'auto' to set starting point for animated height transition
      const newKeywordInspirationsHeight = keywordInspirations.scrollHeight - claimHeight;
      keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;
      searchElements.style.height = `auto`;
      setTimeout(() => {
        keywordInspirations.style.height = `auto`;
      }, 250);
    }
  });

  const expandMoods = () => {
    moods.style.height = `${moods.scrollHeight + 44}px`;
    moods.style.paddingBottom = "22px";
    moods.style.paddingTop = "22px";
    let claimHeight = 0;
    if (claim) {
      claimHeight = claim.scrollHeight;
      claim.style.height = `${claimHeight}px`;
    }
    const newKeywordInspirationsHeight = moods.scrollHeight + 38 + claimHeight + spacer.scrollHeight + 44;
    const searchElementsHeight = searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight;
    keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;
    searchElements.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;
    searchComponent.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;
    isMoodsExpanded = true;
    setTimeout(() => {
      moods.style.height = `auto`;
      searchElements.style.height = `auto`;
      if (claim) {
        claim.style.height = `auto`;
      }
      keywordInspirations.style.height = `auto`;
    }, 250);
  };

  const collapseMoods = () => {
    moods.style.paddingBottom = "0px";
    moods.style.paddingTop = "0px";
    moods.style.height = "0px";
    let claimHeight = 0;
    if (claim) {
      claimHeight = claim.scrollHeight;
    }
    if (keywordInspirations.style.height == `auto`) {
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`; // set fist from auto to number for scroll animation
    }
    const newKeywordInspirationsHeight = 38 + claimHeight + spacer.scrollHeight;
    keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;
    const searchElementsHeight = searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight;
    searchElements.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;
    searchComponent.style.height = searchElements.style.height;
    isMoodsExpanded = false;
    setTimeout(() => {
      searchElements.style.height = `auto`;
    }, 250);
  };

  const toggleMoods = () => {
    if (isMoodsExpanded) {
      collapseMoods();
      window._paq.push(["trackEvent", "keyword-inspirations", "closed"]);
    } else {
      expandMoods();
      window._paq.push(["trackEvent", "keyword-inspirations", "opened"]);
    }
  };

  const adjustMoodsHeight = () => {
    if (keywordInspirations.style.height != "0px") {
      if (moods.style.height > 0) {
        moods.style.height = `auto`;
        keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
      }
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
      moodsRect = moods.getBoundingClientRect();
      const newKeywordInspirationsHeight = moodsRect.height + 38 + claim.scrollHeight + spacer.scrollHeight;
      keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;
      const scrolledDownHeight = `${searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight + keywordInspirations.scrollHeight}px`;

      searchElements.style.height = scrolledDownHeight;
      searchComponent.style.height = scrolledDownHeight;
    } else {
      searchElements.style.height = searchFieldContainer.scrollHeight + `px`;
    }
  };

  onMount(() => {
    searchComponent = document.querySelector("#search-component");
    searchElements = document.querySelector("#search-elements");
    keywordInspirations = document.querySelector("#keyword-inspirations");
    moods = document.querySelector("#moods");
    moodsRect = moods.getBoundingClientRect();
    claim = document.querySelector("#claim");
    searchFieldContainer = document.querySelector("#search-field-container");
    keywordsDiv = document.querySelector("#keywords");
    spacer = document.querySelector("#spacer");
    keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    keywordInspirations.style.top = `0px`;

    window.addEventListener("resize", adjustMoodsHeight);

    return () => {
      window.removeEventListener("resize", adjustMoodsHeight);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keyword-inspirations">
  <div id="moods">
    {#each keywordsArray as keyword}
      <Keyword {keyword} showChevron={true} />
    {/each}
  </div>
  <button id="mood-button" class="inspiration-keyword-button" on:click={toggleMoods}>
    <div class="chevron {isMoodsExpanded ? 'open' : ''}" />
    <img src="/icons/icon_mood.svg" alt="mood" />
    {isMoodsExpanded ? $_("hide_mood_inspirations") : $_("show_mood_inspirations")}
  </button>
  {#if $startup_state}
    <!-- <div id="claim" style="height:{$startup_state ? 'auto' : '0'}"> -->
    <div id="claim">
      <h1>{$_("claim")}</h1>
    </div>
  {/if}
  <div id="spacer" />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #keyword-inspirations {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: start;
    position: relative;
    margin-top: -1px;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 0px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    transition: all 0.25s ease-in-out;
  }
  #moods {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    overflow: hidden;
    height: 0;
    background: var(--white-10);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    transition: all 0.25s ease-in-out;
    border-radius: 0 0 24px 24px;
    box-sizing: border-box;
  }
  .inspiration-keyword-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--white-50);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0 0 0.75rem 0.75rem;
    font-size: 1rem;
    gap: 2px;
    height: 38px;
    text-align: center;
    word-wrap: break-word;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid transparent;
    background: var(--white-10);
  }
  .inspiration-keyword-button:hover {
    color: var(--white);
    border-left: 1px solid var(--white);
    border-right: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
  }
  .chevron {
    background-image: url("/icons/icon_chevron.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-left: -6px;
    transform: rotate(-90deg);
    filter: opacity(50%);
  }
  .chevron.open {
    transform: rotate(180deg);
  }
  .inspiration-keyword-button:hover > .chevron {
    filter: opacity(100%);
  }
  .inspiration-keyword-button img {
    filter: opacity(50%);
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .inspiration-keyword-button:hover img {
    filter: opacity(100%);
  }
  #claim {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    box-sizing: border-box;
  }
  h1 {
    font-weight: 600;
    color: var(--brightest-red);
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: uppercase;
    box-sizing: border-box;
  }
  #spacer {
    padding-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 480px) {
    /*  .inspiration-keyword {
      margin-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
      white-space: nowrap; }*/
  }
</style>
