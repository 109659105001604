<script>
  export let availability;
  export let movie;
  import { _, locale } from "svelte-i18n";
  import { keywords_store } from "$lib/store.js";
  import streamerIcons from "$lib/SVGs.js";
  import { iconAudioLanguage, iconSubtitleLanguage } from "$lib/SVGs.js";

  function trackClick() {
    const KeywordTextValues = $keywords_store.map((item) => item.displayText);
    const joinedKeywordTextValues = KeywordTextValues.join(", ");
    const KeywordIDs = $keywords_store.map((item) => item.id);
    const joinedKeywordIDs = KeywordIDs.join(", ");
    // console.log(
    //   `clicked streaming-link: ${availability.url}; streamer: ${availability.streamer}; movie-name: ${movie.name}; movie-original-name: ${movie.original_name}; movie-id: ${movie.id}; locale: ${$locale}; search-keywords: ${joinedKeywordTextValues}; Keyword-ids: ${joinedKeywordIDs}`
    // );
    // push the tracking data to matomo
    window._paq.push([
      "trackEvent",
      "stream-link",
      "clicked",
      `streamer: ${availability.streamer}; movie-name: ${movie.name}; movie-original-name: ${movie.original_name}; streaming-link: ${availability.url}; movie-id: ${movie.id}; locale: ${$locale}; search-keywords: ${joinedKeywordTextValues}; Keyword-ids: ${joinedKeywordIDs}`
    ]);
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div
  class="streamer-item"
  role="button"
  tabindex="0"
  on:click={trackClick}
  on:click={window.open(availability.url, "_newtab")}
  on:keypress={window.open(availability.url, "_newtab")}>
  <div class="streamer-item-img" title={availability.streamer}>
    <img src={streamerIcons[availability.streamer]} class="streamer-image" alt="{availability.streamer} icon" />
  </div>
  <div class="streamer-item-content">
    <h1>
      {availability.streamer}
    </h1>
    <div title="{$_('audio-languages')}: {availability.audios.join(', ')}" class="language-label">
      <div style="display: flex;">
        <img src={iconAudioLanguage} class="language-icon" alt="audio languages" />
        <div class="text-limit">
          {availability.audios.length > 0 ? availability.audios.join(", ") : "-"}
        </div>
      </div>
    </div>
    <div title="{$_('subtitle-languages')}: {availability.subtitles.join(', ')}" class="language-label">
      <div style="display: flex;">
        <img src={iconSubtitleLanguage} class="language-icon" alt="subtitle languages" />
        <div class="text-limit">
          {availability.subtitles.length > 0 ? availability.subtitles.join(", ") : "-"}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .streamer-item {
    background-color: var(--light-blue);
    border: 1px solid transparent;
    border-radius: 0.75rem;
    display: flex;
    margin-top: 6px;
    min-height: 72px;
    /* width: 257px; */
    border: 1px solid transparent;
  }
  .streamer-item:hover {
    cursor: pointer;
    background-color: var(--lightest-blue);
    border: 1px solid var(--white);
  }
  .streamer-item:hover .language-label {
    background-color: rgb(22, 120, 194);
  }
  .language-label {
    background-image: none;
    background-color: var(--lightest-blue);
    max-width: 40%;
    overflow: hidden;
    border-radius: 0.75rem;
    margin-left: 0.2rem;
    margin-right: 0;
    padding: 0.4em 0.8em;
    line-height: 0.85714286em;
    color: var(--white);
    font-size: 0.85714286rem;
    display: inline-block;
    text-transform: none;
    transition: background 0.1s ease;
    vertical-align: initial;
    box-sizing: inherit;
  }
  .streamer-item-img {
    margin-left: 8px;
    margin-top: 8px;
  }
  .streamer-image {
    border-radius: 0.5rem;
    height: 56px;
    width: 56px;
  }
  .streamer-item-content {
    margin-left: 6px;
    margin-top: 6px;
    width: 100%;
  }
  .streamer-item-content h1 {
    margin-top: 0;
    font-size: 0.9rem;
    min-height: 1.4rem;
    font-weight: 600;
    color: var(--true-black);
  }

  .text-limit {
    /* max-width: 40px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: small;
    /* font-weight: 300; */
  }
  .language-icon {
    margin-right: 0.5rem;
  }
</style>
