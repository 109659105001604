<script>
  import { createEventDispatcher } from "svelte";
  import Thumb from "./Thumb.svelte";

  const dispatch = createEventDispatcher();

  let min = 0;
  let max = 100;
  let pos;
  let value = [min, max];
  let active = false;

  export { min, max, value };

  $: if (active) setValue(pos);
  $: if (!active) setPos(value);
  $: if (active) pos = checkPos(pos);
  $: {
    if (min || max) {
      clamp();
    }
  }
  $: progress = `
    left: ${Math.min(pos[0], pos[1]) * 100}%;
    right: ${100 - Math.max(pos[0], pos[1]) * 100}%;
  `;

  function setValue(pos) {
    const width = max - min;
    value = pos.map((v) => min + v * width).map((v) => Math.round(v));
    dispatch("input", value);
  }

  function setPos(value) {
    pos = value.map((v) => Math.min(Math.max(v, min), max)).map((v) => (v - min) / (max - min));
  }

  function checkPos(pos) {
    return [Math.min(...pos), Math.max(...pos)];
  }

  function clamp() {
    setPos(value);
    setValue(pos);
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<input type="number" value={value[0]} />
<input type="number" value={value[1]} />
<div class="track">
  <div class="progress" style={progress} />
  <Thumb bind:pos={pos[0]} on:active={({ detail: v }) => (active = v)}>
    <slot name="left">
      <slot>
        <div class="thumb" />
      </slot>
    </slot>
  </Thumb>

  <Thumb bind:pos={pos[1]} on:active={({ detail: v }) => (active = v)}>
    <slot name="right">
      <slot>
        <div class="thumb" />
      </slot>
    </slot>
  </Thumb>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  input {
    display: none;
  }

  .track {
    margin: 16px 8px;
    position: relative;
    height: 4px;
    width: calc(100% - 16px);
    border-radius: 100vh;
    background: var(--light-grey);
  }

  .progress {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 100vh;
    background: var(--solid-blue);
  }

  .thumb {
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    background: var(--solid-blue);
  }
</style>
