<script>
  import { afterUpdate } from "svelte";
  export let movie;
  export let isOpen;
  export let backdropImageUrl;
  export let movieTrailerUrl;
  export let onPlay = false;
  export let videoPlayBtn = true;
  let finalVideoUrl = "";

  $: if (finalVideoUrl != movieTrailerUrl) {
    onPlay = false;
  }

  function setupPlayer() {
    if (movieTrailerUrl.includes("youtube")) {
      // Extract video ID using a regular expression for Youtube
      var regExp =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      var matchYoutube = movieTrailerUrl.match(regExp);

      if (matchYoutube && matchYoutube[1]) {
        finalVideoUrl = `https://www.youtube-nocookie.com/embed/${matchYoutube[1]}?autoplay=1`;
      }
    } else if (movieTrailerUrl.includes("vimeo")) {
      // Extract video ID using a regular expression for Vimeo
      var regExpVimeo = /(?:vimeo\.com\/)(?:.*\/)*(\d+)/;
      var matchVimeo = movieTrailerUrl.match(regExpVimeo);

      if (matchVimeo && matchVimeo[1]) {
        finalVideoUrl = `https://player.vimeo.com/video/${matchVimeo[1]}?autoplay=1`;
      }
    }
  }

  afterUpdate(() => {
    if (movie && !movie.videos.length) {
      videoPlayBtn = false;
    }
    detectMouseMove();
    detectTrailerEnd();
    var visual = document.getElementById("visual");
    if (onPlay) {
      buildVideoPlayer();
      if (window.innerWidth <= 720) {
        visual.style.height = "calc(54vw + 60px)";
      } else if (window.innerWidth <= 1000) {
        visual.style.height = "calc(54vw + 60px)";
      } else {
        visual.style.height = "calc(42vw + 70px)";
      }
    } else if (!onPlay) {
      visual.style.height = "calc(36vw + 60px)";
    }
    setupPlayer();
  });

  function detectTrailerEnd() {
    var trailer = document.getElementById("video-iframe");
    if (!isOpen) {
      return;
    } else if (trailer) {
      trailer.addEventListener("ended", function () {
        onPlay = !onPlay;
      });
    }
  }

  function detectMouseMove() {
    var container = document.getElementById("container");
    if (!isOpen) {
      return;
    } else if (container) {
      var button = document.getElementById("stopButton");
      var timeoutId;
      container.addEventListener("mouseenter", function () {
        clearTimeout(timeoutId);
        button.style.display = "block";
      });
      container.addEventListener("mousemove", function () {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
          button.style.display = "none";
        }, 2000);
        if (button.style.display === "none") button.style.display = "block";
        // 2000 milliseconds (2 seconds) delay before hiding the button
      });
      container.addEventListener("mouseleave", function () {
        clearTimeout(timeoutId);
        button.style.display = "none";
      });
    }
  }

  function buildVideoPlayer() {
    const videoContainer = document.getElementById("video-container");
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";
    iframe.src = finalVideoUrl;
    iframe.allow = "autoplay; fullscreen; picture-in-picture";
    iframe.allowfullscreen = true;
    iframe.frameborder = 0;
    iframe.classList.add("video-iframe");
    iframe.setAttribute("id", "video-iframe");
    videoContainer.appendChild(iframe);
  }

  function playVideo() {
    onPlay = !onPlay;
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<!-- {#if !iosDevice} -->
<div id="visual">
  {#if !onPlay}
    {#if videoPlayBtn}
      <button class="play-button" id="playButton" on:click={playVideo} />
    {/if}
    <div class="backdrop-image-container">
      <img src={backdropImageUrl} class="backdrop-image" alt="Movie Backdrop" />
    </div>
  {:else if onPlay}
    <div class="vplayer-container" id="container">
      <div class="video-style">
        <button class="stop-button" id="stopButton" on:click={playVideo} />
        <div class="video-container" id="video-container" />
      </div>
    </div>
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (height:width) */
  }

  #visual {
    width: 100%;
    height: calc(46vw + 60px);
    position: relative;
    overflow: hidden;
    transition: height 0.3s;
  }
  .play-button {
    background: url(/buttons/btn_play.svg);
    background-repeat: no-repeat;
    opacity: 0.8;
    width: 12%;
    aspect-ratio: 1 / 1;
    border: none;
    background-position: center center;
    position: absolute;
    top: calc(10vw * 16 / 9);
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1010;
    background-size: 100%;
  }
  .play-button:hover {
    background: url(/buttons/btn_play_hover.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .stop-button {
    background: url(/buttons/btn_stop.svg);
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.8;
    width: 12%;
    aspect-ratio: 1 / 1;
    border: none;
    position: absolute;
    top: calc(10vw * 16 / 9);
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1010;
    display: none;
    background-size: 100%;
  }
  .video-style {
    z-index: -1;
  }
  .stop-button:hover {
    background: url(/buttons/btn_stop_hover.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  .vplayer-container {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }
  .backdrop-image {
    width: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 13, 0) 100%);
    -webkit-mask-image: linear-gradient(180deg, rgb(0, 0, 0, 1) 50%, rgba(0, 0, 13, 0) 100%);
  }
  .backdrop-image-container {
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    right: 0;
  }
  .backdrop-image-container img {
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
    max-height: 100%; /* Ensure the image doesn't exceed the container height */
  }
  @media (max-width: 720px) {
    .play-button,
    .play-button:hover,
    .stop-button,
    .stop-button:hover {
      top: calc(10vw * 24 / 9);
    }
    #visual {
      height: calc(54vw + 60px);
    }
  }
</style>
