// Documentation link: https://kit.svelte.dev/docs/routing#page-page-js

export const load = async ({ fetch }) => {
  try {
    const response = await fetch("/cache.json");
    if (!response.ok) {
      throw new Error(`Failed to load cache: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to load frontend cache", error);
    return {};
  }
};
