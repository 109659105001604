const streamerIcons = {
  "Netflix": "/streamers/b33ef188-95d9-4556-913a-e40f30c4521c.svg",
  "Sooner": "/streamers/c3f3eb36-bf33-496b-9c3f-ae9a79068c68.svg",
  "Playsuisse": "/streamers/695f4169-442b-497a-8248-74bd7d6ff9d9.svg",
  "Artfilm": "/streamers/9d986710-ebd1-4b9f-83e5-780f17aa4c10.svg",
  "Filmingo": "/streamers/1c9fec31-5776-4b96-b6b6-45fc9b8f1527.svg",
  "Amazon Prime Video": "/streamers/066d56fd-3f72-4e5b-9309-3ab1bfb7115e.svg",
  "Mubi": "/streamers/ca0bda9b-e68e-4a2e-9555-c112a979e4d5.svg",
  "Paramount+": "/streamers/337b5edf-1cb6-4bb3-b91c-72fb3984e537.svg",
  "Apple TV": "/streamers/fc4be627-9414-4f5b-91eb-5756960aed63.svg",
  "Disney+": "/streamers/d667bd5f-112c-4751-befd-7c794ec8cd96.svg",
  "Kino On Demand": "/streamers/1fa54555-500e-4204-b06c-361ec2b8e453.svg",
  "MyFilm": "/streamers/16316f57-f7aa-4904-9e34-1c2749f096e2.svg",
  "Cinefile": "/streamers/9f352402-713c-4f6c-bd7f-750e3d125a1c.svg",
  "Cinu": "/streamers/98d23a58-b73b-4f43-86e9-753255508287.svg"
};
export default streamerIcons;

export const iconAudioLanguage = "/icons/icon_streaminglink_audio-language.svg";
export const iconSubtitleLanguage = "/icons/icon_streaminglink_subtitle-language.svg";
