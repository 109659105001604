<script>
  import MovieItem from "$lib/MovieItem.svelte";
  import Teads from "$lib/Teads.svelte";
  import Footer from "$lib/Footer.svelte";
  // import { recommended_titles, animation_finished, all_posters_loaded } from "$lib/store.js"; // Activate for automatically retrieving movie.getDetails:
  import { recommended_titles, animation_finished } from "$lib/store.js";
  import { movieCount } from "$lib/search.js";
  import { onMount, afterUpdate } from "svelte";

  let newPosters = false;
  let showJumpToTop = false;
  let footer;
  let scrollY = 0;
  let buttonBottom = 8;
  // let loadedPosters = 0; // Activate for automatically retrieving movie.getDetails

  $: if ($recommended_titles) {
    newPosters = true;
  }

  $: showJumpToTop = scrollY > 100;

  function scrollToTop() {
    window.scrollTo({ top: 1, behavior: "smooth" });
  }

  function handleScroll() {
    scrollY = window.scrollY;
    if (footer) {
      const footerRect = footer.getBoundingClientRect();
      const footerTop = footerRect.top;
      const viewportHeight = window.innerHeight;
      if (footerTop < viewportHeight) {
        buttonBottom = viewportHeight - footerTop + 5;
      } else {
        buttonBottom = 8; // Default position
      }
    }
  }

  // Activate for automatically retrieving movie.getDetails:
  // function checkAllPostersLoaded() {
  //   loadedPosters++;
  //   if (loadedPosters === $recommended_titles.length) {
  //     all_posters_loaded.update(() => {
  //       return true;
  //     });
  //   }
  // }

  onMount(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  afterUpdate(() => {
    if (newPosters) {
      // scrollToTop();
      newPosters = false;
    }

    handleScroll();
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="movielist-container">
  <Teads />
  {#if $animation_finished}
    {#if $recommended_titles.length > 0}
      {#each $recommended_titles as item, index}
        <!-- Activate for automatically retrieving movie.getDetails: <MovieItem {item} {index} on:imageLoaded={checkAllPostersLoaded} onLoad={checkAllPostersLoaded} /> -->
        <MovieItem {item} {index} />
      {/each}
    {:else}
      {#each new Array(movieCount) as placeholder}
        <div class="movie-item-placeholder" key={placeholder} />
      {/each}
    {/if}
  {/if}
</div>
<button
  on:click={scrollToTop}
  bottom={buttonBottom}
  class="jump-to-top"
  style="visibility: {showJumpToTop ? 'visible' : 'hidden'}; bottom:{buttonBottom}px;" />
<div bind:this={footer}>
  <Footer />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .movielist-container {
    display: grid;
    margin-top: 55px;
    margin-bottom: 55px;
    margin-left: 80px;
    margin-right: 80px;
    grid-template-rows: repeat(1, minmax(200px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 48px 52px;
  }

  @media (max-width: 480px) {
    .movielist-container {
      margin-left: 50px;
      margin-right: 50px;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }

  .movie-item-placeholder {
    position: relative;
    width: 100%;
    height: 100%;
    /* color: var(--solid-red);
    font-size: 1.5rem;
    text-align: left;
    background-color: var(--dark-grey);
    border-radius: 0.5rem; */
  }

  .jump-to-top {
    position: fixed;
    right: 50%; /* Distance from right */
    height: 48px;
    width: 48px;
    z-index: 100; /* Ensure it's above other content */
    background-image: url("/buttons/btn_up.svg");
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
    border: none;
    transform: translateX(50%);
  }
</style>
