<script>
  import { _ } from "svelte-i18n";
  import MultiSelect from "svelte-multiselect";
  import { language_options } from "$lib/language-options.js";
  import { settings_store, initialSettings, startup_state } from "$lib/store.js";
  import Slider from "$lib/slider/Slider.svelte";
  import Select from "svelte-select";
  import { getTitles } from "$lib/search.js";
  import { get } from "svelte/store";
  import { onMount } from "svelte";
  // import { slide } from "svelte/transition";

  let disabled = false;
  let old_setting_values = JSON.stringify(get(settings_store));

  let openFilter = null;

  let showFilters;
  let filtersComponent;
  let filtersComponentHeight;
  let filters;
  let multiselectRef1;
  let multiselectRef2;
  let largerScreen;

  $: settingYearIsDefault =
    $settings_store.production_period.length === initialSettings.production_period.length &&
    $settings_store.production_period.every((value, index) => value === initialSettings.production_period[index]);
  $: settingLanguageIsDefault =
    $settings_store.subtitle_languages.length === initialSettings.subtitle_languages.length &&
    $settings_store.subtitle_languages.every((value, index) => value === initialSettings.subtitle_languages[index]) &&
    $settings_store.audio_languages.length === initialSettings.audio_languages.length &&
    $settings_store.audio_languages.every((value, index) => value === initialSettings.audio_languages[index]);
  $: settingRegionIsDefault = $settings_store.region === initialSettings.region;
  $: settingsDefault = settingYearIsDefault && settingLanguageIsDefault && settingRegionIsDefault;

  $: if (largerScreen) {
    filters.style.display = "flex";
  } else if (filters && !showFilters) {
    filters.style.display = "none";
  }

  function handleResize() {
    largerScreen = window.innerWidth > 480;
  }

  // function exitNoChange() {
  //   settings_store.set(old_setting_values);
  //   isOpen = false;
  //   dispatch("close");
  // }

  function reset(settingProperty) {
    $settings_store[settingProperty] = initialSettings[settingProperty];
  }

  function toggleFilters() {
    showFilters = !showFilters;
    // const filtersID = document.getElementById("filters");
    if (showFilters) {
      filters.style.display = "flex";
      if ($startup_state) {
        startup_state.set(false);
      }
      window._paq.push(["trackEvent", "filters-icon", "clicked"]);
    } else if (filters) {
      filters.style.display = "none";
    }
  }

  function toggleDropdown(event, filterName) {
    // openFilter = openFilter === filterName ? null : filterName;
    if (!openFilter && openFilter != filterName) {
      openFilter = filterName;
      window._paq.push(["trackEvent", "filter-button", "clicked", filterName]);
      if ($startup_state) {
        startup_state.set(false);
      }
      setTimeout(() => {
        positionDropdown(event.target, filterName);
      }, 0);
    } else {
      openFilter = null;
    }
  }

  function positionDropdown(buttonID, filterName) {
    const filter = document.getElementById(filterName);
    const filterRect = filter.getBoundingClientRect();
    const parentRect = buttonID.parentNode.getBoundingClientRect();
    const dropdownWidth = filterRect.width;
    const viewportWidth = window.innerWidth;
    const margin = 0.03 * viewportWidth; // 3% margin from viewport
    let parentLeftPosition = parentRect.left;
    let leftPosition = parentRect.width / 2 - dropdownWidth / 2;
    // Ensure the dropdown respects the viewport margins
    if (parentLeftPosition + leftPosition < margin) {
      leftPosition = margin - parentLeftPosition;
    } else if (parentLeftPosition + leftPosition + dropdownWidth + margin > viewportWidth - margin) {
      leftPosition = parentRect.width + (viewportWidth - parentRect.right - margin) - dropdownWidth;
    }
    filter.style.left = `${leftPosition}px`;
  }

  function handleClickOutside(event) {
    setTimeout(() => {
      const filterRef = document.getElementById(openFilter);
      if (
        openFilter !== null &&
        filterRef !== event.target &&
        filterRef.parentNode !== event.target.parentNode && // don't trigger, when the button belongs to the filter
        !filterRef.contains(event.target) //&&
      ) {
        checkSettingValues();
        openFilter = null;
      } else if (openFilter && filterRef.parentNode === event.target.parentNode) {
        checkSettingValues();
      }
    }, 10);
  }

  function checkSettingValues() {
    const new_values = JSON.stringify($settings_store);
    if (old_setting_values !== new_values) {
      old_setting_values = new_values;
      getTitles();
    }
  }

  function updatefiltersComponentHeight() {
    // calculate the filtersComponent height based on the height of all containing divs
    filtersComponentHeight =
      Array.from(filtersComponent.children).reduce((total, div) => total + div.offsetHeight + 0, 0) + "px";
  }

  onMount(() => {
    // Multiselect workaround to avoid text cursor on input field
    const inputFields1 = multiselectRef1?.querySelectorAll("input");
    inputFields1[1].setAttribute("readonly", "true");
    const inputFields2 = multiselectRef2?.querySelectorAll("input");
    inputFields2[1].setAttribute("readonly", "true");
    window.addEventListener("resize", handleResize);
    window.addEventListener("pointerdown", handleClickOutside);
    handleResize();
    const observer = new ResizeObserver(updatefiltersComponentHeight);
    if (filters) observer.observe(filters);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("pointerdown", handleClickOutside);
      observer.disconnect();
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="filters-component" bind:this={filtersComponent} style="height: {filtersComponentHeight}">
  <button
    id="filter-button"
    class="{showFilters ? 'open ' : ''}{!settingsDefault ? 'active ' : ''}"
    tabindex="0"
    alt="filters"
    title="filters"
    on:click={toggleFilters}
    on:keydown={toggleFilters} />
  <div id="filters" bind:this={filters}>
    <div id="release-year" class="filter">
      <div
        id="release-year-button"
        class="button {openFilter == 'release-year-settings' ? 'open ' : ''}{!settingYearIsDefault ? 'active ' : ''}"
        role="button"
        tabindex="0"
        on:click={(event) => toggleDropdown(event, "release-year-settings")}
        on:keypress={(event) => toggleDropdown(event, "release-year-settings")}>
        <div class="chevron {openFilter == 'release-year-settings' ? 'open' : ''}" />
        {$_("production-year")}
      </div>
      <div id="release-year-settings" class="setting-block {openFilter == 'release-year-settings' ? 'open' : ''}">
        {#if !settingYearIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:click={() => reset("production_period")}
            on:keypress={() => reset("production_period")} />
        {/if}
        {$settings_store.production_period[0]} - {$settings_store.production_period[1]}
        <Slider
          min={initialSettings.production_period[0]}
          max={initialSettings.production_period[1]}
          bind:value={$settings_store.production_period} />
      </div>
    </div>
    <div id="screening-languages" class="filter">
      <div
        id="screening-languages-button"
        class="button {openFilter == 'languages-settings' ? 'open ' : ''}{!settingLanguageIsDefault ? 'active ' : ''}"
        role="button"
        tabindex="0"
        on:click={(event) => toggleDropdown(event, "languages-settings")}
        on:keypress={(event) => toggleDropdown(event, "languages-settings")}>
        <div class="chevron {openFilter == 'languages-settings' ? 'open' : ''}" />
        {$_("screening-languages")}
      </div>
      <div id="languages-settings" class="setting-block {openFilter == 'languages-settings' ? 'open' : ''}">
        {#if !settingLanguageIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:click={() => {
              reset("audio_languages");
              reset("subtitle_languages");
            }}
            on:keypress={() => reset("audio_languages")} />
        {/if}
        <div id="languages-audio-settings">
          <h3>{$_("audio-languages")}:</h3>
          <span>({$_("none-means-any")})</span>
        </div>
        <div class="languages" bind:this={multiselectRef1}>
          <MultiSelect
            options={language_options}
            {disabled}
            bind:selected={$settings_store.audio_languages}
            inputmode={"none"}
            inputStyle="user-select: none; cursor: pointer; appearance: none;" />
        </div>
        <div id="languages-subtitles-settings">
          <h3>{$_("subtitle-languages")}:</h3>
          <span>({$_("none-means-any")})</span>
          <div class="languages" bind:this={multiselectRef2}>
            <MultiSelect
              options={language_options}
              {disabled}
              bind:selected={$settings_store.subtitle_languages}
              inputStyle="user-select: none; cursor: pointer; appearance: none;"
              inputmode={"none"} />
          </div>
        </div>
      </div>
    </div>
    <div id="region" class="filter">
      <div
        id="region-button"
        class="button {openFilter == 'region-settings' ? 'open ' : ''}{!settingRegionIsDefault ? 'active ' : ''}"
        role="button"
        tabindex="0"
        on:click={(event) => toggleDropdown(event, "region-settings")}
        on:keypress={(event) => toggleDropdown(event, "region-settings")}>
        <div class="chevron {openFilter == 'region-settings' ? 'open' : ''}" />
        {$_("region-setting")}
      </div>
      <div id="region-settings" class="setting-block {openFilter == 'region-settings' ? 'open' : ''}">
        {#if !settingRegionIsDefault}
          <button
            title={$_("reset")}
            class="reset-button"
            on:click={() => reset("region")}
            on:keypress={() => reset("region")} />
        {/if}
        <Select
          items={[
            { value: "DE", label: $_("germany") },
            { value: "CH", label: $_("switzerland") },
            { value: "AT", label: $_("austria") }
          ]}
          value={$settings_store.region}
          bind:justValue={$settings_store.region}
          clearable={false}
          searchable={false}
          inputStyles="user-select: none; cursor: pointer; appearance: none;"
          --border="white 1px solid"
          --border-radius="4px"
          --border-color="var(--icon-light-blue)"
          --height="2em"
          --item-color="var(--white)"
          --selected-item-color="var(--white)"
          --item-hover-bg="var(--white-30)"
          --list-background="var(--dark-grey)"
          --item-is-active-bg="var(--solid-blue)"
          --background="var(--dark-grey)" />
      </div>
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #filters-component {
    display: grid;
    width: 94%;
    /* margin-top: 12px; */
    align-self: center;
    transition: height 0.25s;
  }
  #filter-button {
    display: none;
    background: url("/buttons/btn_filters.svg") no-repeat;
    border: 1px solid var(--white-10);
    border-radius: 8px;
    cursor: pointer;
    height: 48px;
    aspect-ratio: 1 / 1;
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  #filter-button:hover {
    background: url("/buttons/btn_filters_hover.svg") no-repeat;
    border: 1px solid var(--white);
  }
  #filter-button.open {
    background: url("/buttons/btn_filters_open.svg") no-repeat;
    background-color: var(--dark-grey);
  }
  #filter-button.open:hover {
    background: url("/buttons/btn_filters_open_hover.svg") no-repeat;
  }
  #filter-button.active {
    background: url("/buttons/btn_filters_active.svg") no-repeat;
    background-color: var(--lightest-blue);
  }
  #filter-button.active:hover {
    background: url("/buttons/btn_filters_active_hover.svg") no-repeat;
    background-color: var(--lightest-blue);
    border: 1px solid var(--white);
  }
  #filters {
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    justify-content: center;
    position: relative;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    padding: 0 12px 12px;
    gap: 6px;
    align-items: center;
  }
  .filter {
    position: relative;
    display: inline-flex;
    width: max-content;
  }
  .button {
    display: inline-flex;
    width: max-content;
    border-radius: 8px;
    border: 1px solid var(--white-10);
    color: var(--white-50);
    cursor: pointer;
    /* background-image: url("/icons/icon_arrow_down.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 12px center; */
    padding: 6px 24px 6px 18px;
    box-sizing: border-box; /* Include padding in the element's total width calculation */
    gap: 4px;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;

    user-select: none;
  }
  .button:hover {
    border: 1px solid var(--white);
    color: var(--white);
    /* background-image: url("/icons/icon_arrow_down_hover.svg"); */
  }
  .button.open {
    border: 1px solid var(--white-50);
    color: var(--white-50);
    background-color: var(--dark-grey);
    /* background-image: url("/icons/icon_arrow_up.svg"); */
  }
  .button.open:hover {
    /* background-image: url("/icons/icon_arrow_up_hover.svg"); */
    border: 1px solid var(--white);
  }
  .button.active {
    /* background-image: url("/icons/icon_arrow_down_active.svg"); */
    background-color: var(--lightest-blue);
    color: var(--black);
  }
  .chevron {
    background-image: url("/icons/icon_chevron.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-left: -6px;
    transform: rotate(-90deg);
    filter: opacity(50%);
  }
  .chevron.open {
    transform: rotate(0deg);
  }
  .button:hover > .chevron {
    filter: opacity(100%);
  }
  .setting-block {
    display: none;
    border-radius: 8px;
    background-color: var(--dark-grey);
    color: var(--white);
    padding: 1.5em 1em 1em 1em;
    margin-top: 4px;
    width: 400px;
    max-width: 88vw;
    height: max-content;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: -50%;
    z-index: 80;
    transition: background-color 0.25s;
  }
  .setting-block.open {
    display: block;
  }
  h3 {
    color: var(--lightest-blue);
    margin-bottom: 4px;
  }
  #release-year-settings {
    --thumb-bg: var(--solid-blue);
    --progress-bg: var(--solid-blue);
    --track-bg: var(--solid-grey);
  }
  .setting-block:focus {
    border: 1px solid red;
  }
  .languages {
    margin-top: 8px;
    margin-bottom: 8px;
    /* see: https://github.com/janosh/svelte-multiselect#--styling */
    margin-bottom: 2em;
    --sms-text-color: var(--white);
    --sms-options-bg: var(--dark-grey);
    --sms-active-color: var(--white-50);
    --sms-selected-bg: var(--solid-blue);
    --sms-selected-li-padding: 4pt 8pt;
    cursor: pointer;
  }
  #region-settings {
    padding: 24 px;
  }
  .reset-button {
    background: url(/buttons/btn_reset.svg) no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    float: right;
    margin-top: -20px;
    margin-right: -4px;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .reset-button:hover {
    background: url(/buttons/btn_reset_hover.svg) no-repeat;
    background-size: 40px 40px;
  }

  /* Make the slider thumb bigger:
  Observe that the slider is an inactive third party component (https://github.com/doklab/mowizz-search/issues/186) 
  we are going to adopt the slider code, then we have all freedom.
  
  There is also an option to entirely define the 'slot' ourselves, but using the :global construct is simpler.
  see also: https://github.com/BulatDashiev/svelte-slider
  */
  #release-year-settings :global(.thumb) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 480px) {
    #filters {
      display: none;
      width: min-content;
    }
    #filter-button {
      display: block;
      margin: 0 auto auto;
    }
  }
</style>
